<template>
  <div class="company-wrapper">
    <ul>
      <li>
        <span>公司介绍</span>
        <p>
          北京品味天成文化传媒有限公司是国内会展策划，软件开发，影视制作公司。
          自成立以来，秉承"诚信、协作、奉献、超越"的精神，着力打造集战略咨询、
          软件技术和专业服务为一体的综合企业。
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.company-wrapper {
  width: 800px;
  height: 400px;
  margin-top: 30px;
  margin-left: 200px;
  box-sizing: border-box;
}
.company-wrapper p {
  padding: 70px;
}
</style>